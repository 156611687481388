/* Reset básico GERAL*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* TOOLBAR */ 

.toolbar {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  height: 30px; /* Altura da toolbar */
}

.logo {
  height: 40px; /* Ajuste conforme o tamanho desejado para o logo */
  width: auto;
}

/* CAPA */ 

.capa {
  /* background-color: #ff0000; */
  height: 600px; /* Altura da parte vermelha */
  background-size: cover;
  background-position: center;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  text-align: center;
}

.texto-capa {
  font-family: 'Roboto', sans-serif;
  color: white;
  -webkit-text-stroke: 1px black;
  margin-top: -300px; /* Margem de 20px do topo */
  padding: 0 20px; /* Adiciona padding nas laterais para garantir que o texto não toque os bordes em telas pequenas */
  
}

.destaque {
  background-color: #02AD8C; /* Cor de fundo para destaque */
  border-radius: 20px; /* Bordas arredondadas */
  padding: 0px; /* Espaçamento interno para não colar o texto na borda */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Sombra opcional para mais destaque */
}

/* CARROSSEL PRODUTOS */ 

.produto {
  height: 450px; /* Altura da parte verde */
  margin: auto;
  display: flex;
  flex-direction: column; /* Empilha os cards verticalmente por padrão */
  align-items: center; /* Centraliza os cards horizontalmente */
  justify-content: center; /* Centraliza os cards verticalmente */
  gap: 10px; /* Espaço entre os cards */
}

.card {
  width: 200px;
  height: 200px;
  display: flex;
  border: 1px solid #ccc;
  flex-direction: column;
  align-items: center; /* Centraliza o texto verticalmente */
  justify-content: center; /* Centraliza o texto horizontalmente */
  background-color: white;  /* Fundo branco */
  border-radius: 8px;       /* Bordas arredondadas */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-image {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.card-image img {
  width: 100%;  /* Faz a imagem expandir para preencher a largura disponível */
  height: 100%; /* Faz a imagem expandir para preencher a altura disponível */
  object-fit: contain;
}

.card-info {
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.price {
  font-size: 18px;
  font-style: italic;
  font-weight: 500;
  color: black;
  
}
.name-product{
  font-size: 10px;
  color: black;
}

.original-price {
  font-size: 10px;
  font-style: italic;
  color: red;
  text-decoration: line-through; /* Cria o efeito de texto cortado */
}

.buy-button {
  padding: 6px 12px;
  margin-top: 2px;
  background-color: rgb(2, 181, 194);
  color: white;
  border: none;
  cursor: pointer;
  /* transition: background-color 0.3s; */
  /* transition: background-color 0.3s ease; */
  border-radius: 8px;
}

.buy-button:hover {
  background-color:  rgb(2, 140, 150);
}

/* RODA PE */ 
.rodape {
  background-color: #292927;
  height: 70px; /* Altura da parte amarela */
}

.img-facebook{
  width: 40px;
  padding-right: 4px;
}

.img-instagram{
  width: 40px;
  padding-left: 4px;
}

.num_cel{
  text-align:center;
  padding-left: 20px;
}

.social_media{
  text-align: center;
}

h5{
  color: white;
  font-size: 8px;
  font-family: 'Quicksand', sans-serif;
}


/* Estilos responsivos */
@media (min-width: 768px) {
  .toolbar {
    height: 50px; /* Altura maior para dispositivos maiores */
  }
  .capa {
    height: 700px;
    background-image: url('./images/capa.webp')
  }

  .texto-capa {
    font-family: 'Roboto', sans-serif;
    color: white;
    -webkit-text-stroke: 1px black;
    margin-top: -500px; /* Margem de 20px do topo */
    padding: 0 20px; /* Adiciona padding nas laterais para garantir que o texto não toque os bordes em telas pequenas */
  }

  .produto {
    height: 450px;
    flex-direction: row;
  }

  .card {
    width: 350px;
    height: 350px;
    display: flex;
    align-items: center; /* Centraliza o texto verticalmente */
    justify-content: center; /* Centraliza o texto horizontalmente */
  }

  .rodape {
    height: 50px;
  }
}

/* whatsapp */

.whats-app-chat{
  -webkit-animation: bounce;
  animation: bounce;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.wpp{
  -webkit-animation: 'bounce';
  animation: 'bounce';
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  position:fixed;
  width:60px;
  height:60px;
  bottom:40px;
  right:40px;
  background-color:#25d366;
  color:#FFF;
  border-radius:50px;
  text-align:center;
    font-size:30px;
  box-shadow: 2px 2px 3px #999;
    z-index:100;
}

.my-wpp{
margin-top:16px;
}

a{
  text-decoration:none;
}
.floating_btn {
  position: fixed;
  bottom: 30px;
  right: 8px;
  width: 100px;
  height: 100px;
  align-items:center;
  justify-content:center;
  z-index: 1000;
}
.floating_btn img {
  width: 50%; /* Tamanho do ícone dentro do botão */
  margin-top: 15%;
}

@keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

.contact_icon {
  background-color: #42db87;
  color: #fff;
  width: 60px;
  height: 60px;
  font-size:30px;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translatey(0px);
  animation: pulse 1.5s infinite;
  box-shadow: 0 0 0 0 #42db87;
  -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  font-weight: normal;
  font-family: sans-serif;
  text-decoration: none !important;
  transition: all 300ms ease-in-out;
}


.text_icon {
  margin-top: 8px;
  color: #707070;
  font-size: 13px;
}